import React, { useState } from "react"
import Btn from "../Btn"
import Img from "gatsby-image"

import { motion } from "framer-motion"
import { Link, useStaticQuery } from "gatsby"
import { useSupportsWebP } from "../../utils/helpers"
import slugify from "slugify"

import "./PanoramicImages.scss"

const PanoramicImageItem = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false)

  const supportsWebP = useSupportsWebP()
  const imageItemSrc = supportsWebP
    ? item.panoramicImage.localFile.full.fixed.srcWebp
    : item.panoramicImage.localFile.full.fixed.src

  const previewImageFluid = item.previewImage
    ? item.previewImage.localFile.thumb.fluid
    : item.panoramicImage.localFile.thumb.fluid

  //   const previewImageFluid = item.panoramicImage.localFile.thumb.fluid

  const itemSlug = slugify(item.panoramicImageTitle, { lower: true })

  return (
    <div
      className="panoramic-image"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!isHovered) setIsHovered(true)
      }}
    >
      {/* // eslint-disable-line */}
      <motion.div
        transition={{ duration: 0.3 }}
        animate={{ opacity: isHovered ? 0.3 : 1 }}
        className="panoramic-image__preview-image-container"
      >
        <Img
          fluid={previewImageFluid}
          imgStyle={{ objectFit: "cover" }}
          className="panoramic-image__preview-image"
        />
      </motion.div>
      <motion.h5
        transition={{ duration: 0.3 }}
        initial={{ scale: 0.6, opacity: 0.75 }}
        animate={{
          scale: isHovered ? 0.8 : 0.6,
          opacity: isHovered ? 1 : 0.75,
        }}
      >
        {item.panoramicImageTitle}
      </motion.h5>
      <Link to={`/virtual-reality/${itemSlug}-360-image/`}>
        <Btn
          size="sm"
          className="panoramic-image__btn"
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3, delay: isHovered ? 0.15 : 0 }}
          animate={{
            opacity: isHovered ? 1 : 0,
            y: isHovered ? 0 : 20,
            pointerEvents: isHovered ? "" : "none",
          }}
        >
          Open panorma
        </Btn>
      </Link>
    </div>
  )
}

const PanoramicImages = () => {
  const { featured360Images, imagesStandaloneService } = use360ImagesQuery()

  const priceRegex = /(\d+\d{1,2})/g
  const servicePrice = imagesStandaloneService.standAloneServicePriceLine.match(
    priceRegex
  )

  return (
    <>
      <section className="panoramic-images " id="panoramic-images">
        <h2 className="panoramic-images__title">360 Images</h2>
        <p className="panoramic-images__description">
          Full 360 view of one room.
        </p>
        <div className="panoramic-images__price">
          Starting from: <span>${servicePrice}</span>
        </div>
        {/* </div> */}
        <div className="panoramic-images__item-container">
          {featured360Images.map(item => (
            <PanoramicImageItem key={item.panoramicImageTitle} item={item} />
          ))}
        </div>
      </section>
    </>
  )
}

export default PanoramicImages

const use360ImagesQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulVirtualRealityPage {
          imagesStandaloneService {
            standAloneServicePriceLine
          }
          featured360Images {
            panoramicImageTitle
            panoramicImage {
              localFile {
                full: childImageSharp {
                  fixed(width: 8192, quality: 95) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
                thumb: childImageSharp {
                  fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            previewImage {
              localFile {
                thumb: childImageSharp {
                  fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data.contentfulVirtualRealityPage
}
