import { useStaticQuery } from "gatsby"
import React from "react"
import SEO from "../seo"

import PageTransitionLink from "../PageTransitionLink"
import PanoramicImages from "./PanoramicImages"
import VR360Videos from "./VR360Videos"

import VRHero from "./VRHero"
import VRIntro from "./VRIntro"
import VRReadyModel from "./VRReadyModel"

const VirtualRealityPage = () => {
  const {
    virtualRealityPageTitle,
    heroMedia,
    vrReadyModelVideo,
  } = useVirtualRealityQuery()
  return (
    <div>
      <SEO
        title="Virtual Reality"
        description="Virtual Reality is the future and at Max VR we want to be at the
          forefront of innovation."
      />

      <VRHero
        virtualRealityPageTitle={virtualRealityPageTitle}
        heroImage={heroMedia}
      />
      {/* <VRIntro /> */}
      <PanoramicImages />
      <VR360Videos />
      <VRReadyModel vrReadyModelVideo={vrReadyModelVideo} />
      {/* <div className="container" style={{ paddingBottom: "8rem" }}>
        <p className="centered-cta-line">
          Don’t have your own headset? No worries. <br />
          Come see us in studio or we can come to you. <br />{" "}
          <a
            href="mailto:info@maxvr.co.nz?subject=Schedule a VR session with Max VR"
            target="_blank"
          >
            <span className="font-bold">Book now!</span>{" "}
          </a>
        </p>
      </div> */}
    </div>
  )
}

export default VirtualRealityPage

const useVirtualRealityQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulVirtualRealityPage {
          virtualRealityPageTitle
          heroMedia {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            file {
              contentType
              url
            }
            localFile {
              publicURL
            }
          }
          vrReadyModelVideo {
            localFile {
              publicURL
            }
          }
        }
      }
    `
  )
  return data.contentfulVirtualRealityPage
}
