import React, { useEffect } from "react"
import { motion } from "framer-motion"

import { IoIosArrowDown } from "react-icons/io"
import Img from "gatsby-image"

import "./VRHero.scss"
import HeroMediaComponent from "../HeroMediaComponent"
import gsap from "gsap"
import { select } from "../../utils/helpers"

const VRHero = ({ virtualRealityPageTitle, heroImage }) => {
  useEffect(() => {
    const mainHeadline = select(".vr-hero__text-content h1 span")
    const heroParagraph = select(".vr-hero__text-content p")
    const heroMedia = select(".home-hero__image, .home-hero__video")

    const tl = gsap.timeline()
    tl.from(mainHeadline, {
      y: 100,
      opacity: 0,
      duration: 1,
      ease: "power4.out",
      delay: 0.75,
    })
      .from(
        heroParagraph,
        {
          opacity: 0,
          duration: 1,
          ease: "power4.out",
        },
        "<0.75"
      )
      .from(
        heroMedia,
        {
          opacity: 0,
          duration: 2,
          ease: "power2.iOout",
        },
        "<0.75"
      )

    // tl.to("body", { opacity: 0.5 })
  }, [])

  return (
    <section className="vr-hero ">
      <HeroMediaComponent media={heroImage} />
      <div className="vr-hero__text-content">
        <h1 className="overflow-hidden leading-none">
          <span className="block">{virtualRealityPageTitle}</span>
        </h1>
        <p>
          Virtual Reality is the future and at Max VR we want to be at the
          forefront of innovation. VR technology is the easiest way to fully
          immerse yourself in your new project. Whether it is having the ability
          to look all around your new project from the comfort of your own
          device (with 360 imagery), or using a headset and wands to move
          throughout your new home.
        </p>
        <motion.div
          animate={{
            y: [0, 0, 12, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.7, 0.8, 1],
            loop: Infinity,
            repeatDelay: 1,
          }}
        >
          <IoIosArrowDown className="scroll-down-chevron" stroke="0" />
        </motion.div>
      </div>
    </section>
  )
}

export default VRHero
