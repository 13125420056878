import { useStaticQuery } from "gatsby"
import React from "react"

import "./VR360Videos.scss"

const VideoItem = ({ video }) => {
  return (
    <div className="video-item">
      <div className="video-item__iframe-container">
        <iframe
          id="ytplayer"
          type="text/html"
          width="100%"
          height="100%"
          style={{}}
          src={`https://www.youtube.com/embed/${video.youtubeVideoID}?rel=0&autoplay=0&controls=1&showinfo=0&loop=1`}
          frameBorder="0"
          allow="fullscreen"
        />
      </div>
    </div>
  )
}

const VR360Videos = () => {
  const { featured360Videos, videosStandaloneService } = use360VideoQuery()

  const priceRegex = /(\d+\d{1,2})/g
  const servicePrice = videosStandaloneService.standAloneServicePriceLine.match(
    priceRegex
  )

  featured360Videos.forEach(video => {
    const youtubeVideoID = video.youTubeLink.split("?v=")[1].split("&")[0]
    video.youtubeVideoID = youtubeVideoID
  })

  return (
    <section className="vr-360-videos ">
      {/* <div className="container"> */}
      <h2 className="vr-360-videos__title">360 Videos</h2>
      <p className="vr-360-videos__description">
        Full 360 view videos from chosen path.
      </p>
      <div className="vr-360-videos__price">
        Starting from: <span>${servicePrice}</span>
      </div>
      {/* </div> */}
      <div className="vr-360-videos__items-container">
        {featured360Videos.map(video => (
          <VideoItem video={video} key={video.youtubeVideoID} />
        ))}
      </div>
    </section>
  )
}

export default VR360Videos

const use360VideoQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulVirtualRealityPage {
          videosStandaloneService {
            id
            standAloneServicePriceLine
          }
          featured360Videos {
            panoramicVideoTitle
            youTubeLink
          }
        }
      }
    `
  )
  return data.contentfulVirtualRealityPage
}
