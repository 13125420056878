import React from "react"
import "./VRReadyModel.scss"

const VRReadyModel = ({ vrReadyModelVideo }) => {
  const videoUrl = vrReadyModelVideo.localFile.publicURL
  return (
    <section className="vr-ready-model container">
      <h2 className="vr-ready-model__title">VR Ready Model</h2>
      <p className="vr-ready-model__description">
        When investing in a new home, it is important to understand what you are
        paying for and how you will experience the design. We believe every new
        homeowner should utilise VR in the design stage to aid in understanding
        how the home will be experienced and identify changes that could be
        costly to make down the track. <br />
        <br /> If you have a project that has slightly different needs -{" "}
        <a href="mailto:ino@maxvr.co.nz?subject=Max VR Virtual Reality Query">
          <span className="font-semibold "> get in touch!</span>{" "}
        </a>
      </p>
      <video src={videoUrl} autoPlay muted loop className="mx-auto" />
    </section>
  )
}

export default VRReadyModel
